/*
 * @Author: 庄志莹
 * @Date: 2020-12-16 15:34:32
 * @LastEditTime: 2021-05-19 14:51:34
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \device_control\src\main.js
 */
import Vue from 'vue'

//自定义的样式
import '../public/css/reset.css'
import '../public/css/fonts.css'
//ant的样式引入
import 'ant-design-vue/dist/antd.less'



import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
  /* 申请的百度密钥，可以在百度地图官网申请 */
  ak: 'DCKHei6xfXCe3zxCxXiwi6qgezFZXhX1',
})

//全局使用ls
import VueStorage from 'vue-ls'
Vue.use(VueStorage, {
  namespace: 'pro__', // key prefix
  name: 'ls', // name variable Vue.[ls] or this.[$ls],
  storage: 'local', // storage name session, local, memory
})


//ant 框架
import Antd from 'ant-design-vue'
Vue.use(Antd)
Vue.config.productionTip = false


//引入全局变量   wesocket的url
import globalVariable from './libs/global_variable'
Vue.prototype.$globalVariable = globalVariable



import router from './router'
import store from './store'
import { api } from './server/api'
Vue.prototype.$api = api
import App from './App.vue'

// let xiangying=function(){
//     var html = document.documentElement;
//     var htmlw = html.clientWidth;
//     html.style.fontSize = htmlw/144 + "px";
//   }
//   xiangying()
//   window.onresize=xiangying

new Vue({
  router,
  store,
  mounted: function () {
    // Vue.ls.set('foo', 'boo')
    // 设置有效期
    // Vue.ls.set('foo', 'boo', 60 * 60 * 1000 * 24) //有效24小时
  },
  render: (h) => h(App),
}).$mount('#app')
