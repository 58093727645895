<template>
  <div class="layout-header-R">
    <img class="address-img" src="@/assets/img/location-img.png" alt="" />
    <span class="school-address">{{ userInfo.schoolName }}</span>
    <div class="userInfo">
      <a-icon type="fullscreen" v-if="map" @click="goMap" />
      <a-badge @click="goNewMessage()" :count="count" :overflowCount="max">
        <div class="bell"><a-icon type="bell" /></div>
      </a-badge>
      <span class="identity">{{ identity }}</span>
      <!-- 下拉菜单 -->
      <template>
        <a-dropdown placement="bottomCenter">
          <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
            <img
              class="Avatar"
              src="@/assets/img/headportrait.png"
              v-if="avatar === null || avatar === ''"
            />
            <img :src="avatar" class="Avatar" v-else />

            <span class="userName">{{ userInfo.username }}</span>
          </a>
          <a-menu slot="overlay">
            <a-menu-item>
              <a @click="goUser()" href="javascript:;">个人中心</a>
            </a-menu-item>
            <a-menu-item>
              <a @click="goSetPassword()" href="javascript:;">修改密码</a>
            </a-menu-item>
            <a-menu-divider />
            <a-menu-item>
              <a @click="loginOut()" href="javascript:;">退出登录</a>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      count: 0, // 消息通知书 控制小红点显示的数量
      max: 99, //最大的消息数量
      userInfo: {},
      identity: "",
      avatar: null,
    };
  },
  computed: {
    map() {
      return this.$route.path === "/general";
    },
  },
  methods: {
    goUser() {
      this.$router.push(
        { name: "setup" },
        (onComplete) => {},
        (onAbort) => {}
      );
    },
    goSetPassword() {
      this.$router.push(
        { name: "set-password" },
        (onComplete) => {},
        (onAbort) => {}
      );
    },
    goNewMessage() {
      this.$router.push(
        { name: "message" },
        (onComplete) => {},
        (onAbort) => {}
      );
    },
    //退出登录
    loginOut() {
      this.$ls.set("token", null);
      this.$store.commit("setList", []);
      this.$ls.set("userInfo", null);
      this.$router.push("/login");
    },
    //去大屏展示页面
    goMap() {
      this.$router.push("/general/map");
    },
    //获取消息数量
    getMessageList() {
      this.$api.getMessageCount().then((res) => {
        if (res.success) {
          this.count = res.data.count;
        }
      });
    },
  },
  created() {
    this.getMessageList();
    let userInfo = this.$ls.get("userInfo");
    this.userInfo = userInfo;
    this.identity = userInfo.roles[0];
    this.avatar = userInfo.avatar;
  },
};
</script>

<style lang="scss" scope>
.ant-dropdown-menu-item {
  display: flex;
  align-items: center;
  justify-content: center;
}
.layout-header {
  position: relative;
  .address-img {
    width: 16px;
    height: 16px;
    position: absolute;
    top: 22px;
    left: 56px;
  }
  .school-address {
    height: 20px;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.65);
    opacity: 1;
    position: absolute;
    top: 20px;
    left: 80px;
  }
  .userInfo {
    // width: 260px;
    height: 100%;
    position: absolute;
    top: 0px;
    right: 20px;
    display: inline-block;
    .bell {
      width: 20px;
      margin-left: 15px;
    }
    .ant-badge {
      cursor: pointer;
    }
    .identity {
      margin-left: 15px;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.65);
      opacity: 1;
    }
    .ant-dropdown-link {
      display: inline-block;
      height: 50px;
      top: 0px;
      left: 0px;
      margin-left: 15px;
      transform: translateY(-2px);
    }
    .Avatar {
      width: 26px;
      height: 26px;
      border-radius: 50%;
      vertical-align: middle;
      margin-right: 10px;
    }
    .userName {
      vertical-align: middle;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.65);
      opacity: 1;
    }
  }
}
</style>
