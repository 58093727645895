<!--
 * @Author: 庄志莹
 * @Date: 2020-12-10 08:56:44
 * @LastEditTime: 2021-04-28 20:29:01
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \device_control\src\App.vue
-->
<template>
  <div id="app">
    <a-config-provider :locale="locale">
      <router-view />
    </a-config-provider>
  </div>
</template>

<script>
import zhCN from "ant-design-vue/es/locale/zh_CN";
export default {
  name: "App",
  data() {
    return {
      locale: zhCN,
    };
  },
};
</script>

<style lang="scss"></style>
