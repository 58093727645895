/*
 * @Author: 庄志莹
 * @Date: 2020-12-28 16:47:21
 * @LastEditTime: 2021-06-09 11:26:17
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \device_control\src\router\index.js
 */

import Vue from 'vue'
import VueRouter from 'vue-router'
import constantRouterMap from './constantRouterMap'
import lastRouter from './lastRouter'
Vue.use(VueRouter)

import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

import store from '@/store/index'
import Main from '@/views/main/main.vue'

const router = new VueRouter({
  mode: 'history',
  // base: process.env.VUE_APP_BASEURL,
  routes: constantRouterMap,
})


//路由守卫，路由跳转之前的钩子
router.beforeEach((to, from, next) => {
  NProgress.start()
  /* 路由发生变化修改页面title */
  if(to.meta.title){
    document.title = to.meta.title
  }
  
  if (!Vue.ls.get('token')) {
    if (
      to.path === '/enter' ||to.path === '/login' || to.path === '/register' || to.path === '/forget' ||
      to.path === '/scan' || to.path === '/bindWechat' || to.path === '/loginSuccess'
      || to.path === '/bindSuccess' || to.path === '/bindFailure' || to.path === '/bindAnother'
      || to.path === '/privacy' || to.path === '/service' || to.path === '/share'
    ) {
      next()
    }
  } else {
    if (
      to.path === '/enter' ||to.path === '/login' || to.path === '/register' || to.path === '/forget' ||
      to.path === '/scan' || to.path === '/bindWechat' || to.path === '/loginSuccess'
      || to.path === '/bindSuccess' || to.path === '/bindFailure' || to.path === '/bindAnother'
      || to.path === '/privacy' || to.path === '/service' || to.path === '/share'
    ) {
      next()
    } else {
      if (store.state.list.length === 0) {
        store.dispatch('getPermission').then((res) => {
          let routesFilter = res.filter((r) => (r.component = Main))
          // console.log(routesFilter)
          let path = ""
          routesFilter.forEach((r) => {
            if (r.children && r.children.length > 0) {
              r.children.forEach((rr) => {
                const component = rr.component
                rr.component = (resolve) => require(['@/views' + component], resolve)
                if (rr.isRedirect) {
                  path = rr.path
                }
              })
            }
          })
          router.matcher = new VueRouter({
            mode: 'history',
            base: process.env.BASE_URL,
            routes: constantRouterMap,
          }).matcher;
          router.addRoutes(routesFilter)
          router.addRoutes(lastRouter)
          if (to.path === '/main') {
            next({ path: path })
          }
          next({ ...to, replace: true })
        })
      } else {
        if (to.path === '/main') {
          let arr = []
          store.state.list.forEach(r => {
            if (r.children && r.children.length > 0) {
              r.children.forEach(rr => {
                arr.push(rr)
              })
            }
          })
          let arr1 = arr.filter(rr => rr.isRedirect === true)
          let path = arr1[0].path
          next({ path: path })
        }
        next()
      }
    }
  }
})

router.afterEach(() => {
  NProgress.done()
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
    return originalPush.call(this, location).catch(err => err)
}

export default router
