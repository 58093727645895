/*
 * @Author: 庄志莹
 * @Date: 2021-01-05 11:51:46
 * @LastEditTime: 2021-06-04 10:03:10
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \device_control\src\router\last.js
 */
import Main from '@/views/main/main.vue'
const lastRouter = [
    //用户中心
    {
      path: '/user',
      name: 'user',
      meta: {
        title: '用户中心',
      },
      component: () => import('@/views/user/user.vue'),
      children: [
        {
          path: '/user',
          redirect: '/user/setup',
        },
        //基本设置
        {
          path: '/user/setup',
          name: 'setup',
          meta: {
            title: '基本设置',
          },
          component: () => import('@/views/user/setup/setup.vue'),
        },
        //修改密码
        {
          path: '/user/set-password',
          name: 'set-password',
          meta: {
            title: '修改密码',
          },
          component: () => import('@/views/user/set-password/set-password.vue'),
        },
        //新消息通知
        {
          path: '/user/new-message',
          name: 'message',
          meta: {
            title: '新消息通知',
          },
          component: () => import('@/views/user/new-message/new-message.vue'),
        },
      ],
    },
    //终端详情
    {
      path: '/equipment/dev-detail/:serialNum/:buildingName/:classroomName',
      props:true,
      name: 'dev-detail',
      meta: {
        title: '终端详情',
      },
      component: () => import('@/views/equipment/dev-detail/dev-detail'),
    },
    //数据概览大屏展示
    {
      path: '/general/map',
      name: 'general-map',
      meta: {
        title: '数据概览',
      },
      component: () => import('@/views/record/general/map'),
    },
    //参数数据
    {
      path: '/version/params/params',
      component: Main,
      hidden: true,
      children: [
        {
          path: '/params-data/:id',
          props:true,
          component: (resolve) => require(['@/views/version/params-data/paramsData'], resolve),
          name: 'Data',
          meta: { title: '参数数据' }
        }
      ]
    },
    //404页面
    {
      path: '*',
      name: '404',
      meta: {
        title: '404',
      },
      component: () => import('@/views/404/404.vue'),
    },
  ]
  
  export default lastRouter
  