/*
 * @Author: 庄志莹
 * @Date: 2021-03-01 17:00:14
 * @LastEditTime: 2021-03-01 17:32:47
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \device_control\src\libs\global_variable.js
 */
const wsurl = process.env.VUE_APP_WSURL
export default {
    wsurl,
}