/*
 * @Author: your name
 * @Date: 2021-04-26 11:32:53
 * @LastEditTime: 2021-04-26 11:52:36
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \device_control\src\store\index.js
 */

import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
import { api } from '@/server/api'
import router from '../router';
export default new Vuex.Store({
  state: {
    list: [],
    info: [],
    role: [],
    phone: '',
    registerPhone: "",
    schoolNum: "",
    loadding: false
  },
  mutations: {
    setList: (state, data) => {
      state.list = data
    },
    setInfo: (state, data) => {
      sessionStorage.setItem('info', data)
      state.info = data
    },
    setRoleInfo: (state, data) => {
      sessionStorage.setItem('role', data)
      state.role = data
    },
    showloadding(state, load) {
      state.loadding = load
    }
  },
  actions: {
    async getPermission({ commit }) {
      // 取后台路由
      let asyncRouter = await api.getPermissionList()
      // 存储权限列表
      // console.log(asyncRouter)
      if (asyncRouter.success) {
        return new Promise((resolve) => {
          const pml = asyncRouter.data.permissionList.children
          commit('setList', pml)
          resolve(pml)
        })
      }else {
        console.log(asyncRouter)
        router.push({ path: '/' })
      }
    },
    async getPermissionInfo({ commit }) {
      // 获取用户权限信息
      let permissionInfo = await api.getUserInfo()
      return new Promise((resolve) => {
        let pml = permissionInfo.data.permissionValueList
        commit('setInfo', pml)
        resolve(pml)
      })
    },
    // 获取 角色 信息
    async getRoleInfo({ commit }) {
      // 获取角色信息
      let RoleInfo = await api.getUserInfo()
      return new Promise((resolve) => {
        let role = RoleInfo.data
        commit('setRoleInfo', role)
        resolve(role)
      })
    },
    setloadding(context, load) {
      context.commit("showloadding", load);
    }
  },
  getters: {
    isloading: (state) => {
      return state.loadding
    }
  },
  modules: {},
})
