import axios from 'axios'
import Vue from 'vue'
import router from "../router"
axios.defaults.timeout = 5000
axios.defaults.baseURL = process.env.VUE_APP_BASEURL

axios.interceptors.request.use(
  (config) => {
    // config.data = JSON.stringify(config.data)
    config.headers = {
      'Content-Type': 'application/json;charset=UTF-8',
      token: Vue.ls.get('token'),
    }
    //开始显示Loading
    // store.state.loadding = true; //在请求发出之前进行一些操作
    return config
  },
  (err) => {
    //开始显示LoadingBar
    return Promise.reject(err)
  }
)

/*
 * 封装post请求，v1.0.2版本使用
 * @param url
 * @param data
 * @returns {Promise}
 */

function post(url, params) {
  return new Promise((resolve, reject) => {
    axios.post(url, params).then(
      (response) => {
        // store.state.loadding = false;//在这里对返回的数据进行处理
        // console.log(params)
        resolve(response.data)
        if(response.data.code===-4001){
          router.push("/")
        }
      },
      (err) => {
        reject(err)
      }
    )
  })
}

/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */

function get(url, params = {}) {
  return new Promise((resolve, reject) => {
    axios.get(url, { params: params }).then((response) => {
      // store.state.loadding = false;//在这里对返回的数据进行处理
      resolve(response.data)
      if(response.data.code===-4001){
        router.push("/")
      }
    })
      .catch((err) => {
        reject(err)
      })
  })
}

/**

 * 封装patch请求
 * @param url
 * @param data
 * @returns {Promise}
 */

function patch(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios
      .patch(url, data, {
        headers: 'Content-Type: application/json;charset=UTF-8',
      })
      .then(
        (response) => {
          // store.state.loadding = false;//在这里对返回的数据进行处理
          resolve(response.data)
          if(response.data.code===-4001){
            router.push("/")
          }
        },
        (err) => {
          reject(err)
        }
      )
  })
}

/**
 * 封装put请求
 * @param url
 * @param data
 * @returns {Promise}
 */

function put(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.put(url, data).then(
      (response) => {
        // store.state.loadding = false;//在这里对返回的数据进行处理
        resolve(response.data)
        if(response.data.code===-4001){
          router.push("/")
        }
      },
      (err) => {
        reject(err)
      }
    )
  })
}

/**
 * 封装delete请求
 * @param url
 * @param data
 * @returns {Promise}
 */

function remove(url, params = {}) {
  return new Promise((resolve, reject) => {
    axios
      .delete(url, { params: params })
      .then((response) => {
        // store.state.loadding = false;//在这里对返回的数据进行处理
        resolve(response.data)
        if(response.data.code===-4001){
          router.push("/")
        }
      })
      .catch((err) => {
        reject(err)
      })
  })
}

function removeAll(url, list) {
  return new Promise((resolve, reject) => {
    axios
      .delete(url, { data: list })
      .then((response) => {
        // store.state.loadding = false;//在这里对返回的数据进行处理
        resolve(response.data)
        if(response.data.code===-4001){
          router.push("/")
        }
      })
      .catch((err) => {
        reject(err)
      })
  })
}


export default {
  get: get,
  post: post,
  put: put,
  patch: patch,
  remove: remove,
  removeAll: removeAll
}
